<template>
  <v-container fluid>
    <head-title
      text="Proveedores"
      :to="{ name: 'accounting-register-buy-home' }"
    ></head-title>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="searchText"
          label="Buscar"
          placeholder="Buscar por Nombre o NIT"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn class="mx-2" fab dark small color="primary">
          <v-icon dark @click="getProviders()"> mdi-refresh </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" class="text-end">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="primary"
          @click="createProvider()"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="providers"
      :loading="loading"
      hide-default-footer
      disable-sort
      disable-pagination
      class="elevation-1"
    >
      <template v-slot:[`item.credit_available`]="{ item }">
        <v-icon v-if="item.credit_available" dark color="green lighten-1">
          mdi-check-circle-outline
        </v-icon>
        <v-icon v-else dark color="red lighten-1">
          mdi-close-circle-outline
        </v-icon>
      </template>
      <template v-slot:[`item.disable_iva`]="{ item }">
        <v-icon v-if="item.disable_iva" dark color="green lighten-1">
          mdi-check-circle-outline
        </v-icon>
        <v-icon v-else dark color="red lighten-1">
          mdi-close-circle-outline
        </v-icon>
      </template>
      <template v-slot:[`item.available_voucher`]="{ item }">
        <v-icon v-if="item.available_voucher" dark color="green lighten-1">
          mdi-check-circle-outline
        </v-icon>
        <v-icon v-else dark color="red lighten-1">
          mdi-close-circle-outline
        </v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="updateProvider(item)" medium color="blue lighten-1">
          mdi-file-document-edit-outline
        </v-icon>
      </template>
    </v-data-table>
    <form-provider
      v-if="options == 1 || options == 2"
      :dialogProvider="dialogProvider"
      :providerId="providerId"
      :options="options"
      @close="
        dialogProvider = false;
        providerId = 0;
        options = 0;
      "
      :title="title"
      @refresh="getProviders"
    ></form-provider>
    <v-pagination
      v-model="currentPage"
      :total-visible="10"
      :length="totalPages"
      :disabled="loading"
      v-show="totalPages > 0"
    ></v-pagination>
  </v-container>
</template>

<script>
import request from "@/services/requests";

export default {
  name: "ProviderList",
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    FormProvider: () => import("@/components/accounting/EditProvider"),
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 0,
      componentKey: 0,
      title: "",
      searchText: "",
      providers: [],
      loading: false,
      providerId: 0,
      options: 0,
      dialogProvider: false,
      headers: [
        { text: "Proveedor", value: "name" },
        { text: "Dirección", value: "address" },
        { text: "NIT", value: "nit" },
        { text: "Crédito", value: "credit_available" },
        { text: "Contravales", value: "available_voucher" },
        { text: "Pequeño Contribuyente", value: "disable_iva" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.getProviders();
  },
  methods: {
    getProviders() {
      this.loading = true;
      request
        .get(
          "api/catalogs/providers/?search=" +
            this.searchText +
            "&page=" +
            this.currentPage
        )
        .then((data) => {
          this.providers = data.data.results;
          this.searchText = "";
          this.totalPages = data.data.total_pages;
          this.loading = false;
        });
    },
    updateProvider(val) {
      this.dialogProvider = true;
      this.providerId = val.id;
      this.options = 2;
      this.title = "Editar Proveedor";
    },
    createProvider() {
      this.dialogProvider = true;
      this.options = 1;
      this.title = "Nuevo Proveedor";
    },
  },
  watch: {
    currentPage: function () {
      this.getProviders();
    },
  },
};
</script>