var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('head-title',{attrs:{"text":"Proveedores","to":{ name: 'accounting-register-buy-home' }}}),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","placeholder":"Buscar por Nombre o NIT"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.getProviders()}}},[_vm._v(" mdi-refresh ")])],1)],1),_c('v-col',{staticClass:"text-end",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.createProvider()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.providers,"loading":_vm.loading,"hide-default-footer":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.credit_available",fn:function(ref){
var item = ref.item;
return [(item.credit_available)?_c('v-icon',{attrs:{"dark":"","color":"green lighten-1"}},[_vm._v(" mdi-check-circle-outline ")]):_c('v-icon',{attrs:{"dark":"","color":"red lighten-1"}},[_vm._v(" mdi-close-circle-outline ")])]}},{key:"item.disable_iva",fn:function(ref){
var item = ref.item;
return [(item.disable_iva)?_c('v-icon',{attrs:{"dark":"","color":"green lighten-1"}},[_vm._v(" mdi-check-circle-outline ")]):_c('v-icon',{attrs:{"dark":"","color":"red lighten-1"}},[_vm._v(" mdi-close-circle-outline ")])]}},{key:"item.available_voucher",fn:function(ref){
var item = ref.item;
return [(item.available_voucher)?_c('v-icon',{attrs:{"dark":"","color":"green lighten-1"}},[_vm._v(" mdi-check-circle-outline ")]):_c('v-icon',{attrs:{"dark":"","color":"red lighten-1"}},[_vm._v(" mdi-close-circle-outline ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"medium":"","color":"blue lighten-1"},on:{"click":function($event){return _vm.updateProvider(item)}}},[_vm._v(" mdi-file-document-edit-outline ")])]}}],null,true)}),(_vm.options == 1 || _vm.options == 2)?_c('form-provider',{attrs:{"dialogProvider":_vm.dialogProvider,"providerId":_vm.providerId,"options":_vm.options,"title":_vm.title},on:{"close":function($event){_vm.dialogProvider = false;
      _vm.providerId = 0;
      _vm.options = 0;},"refresh":_vm.getProviders}}):_vm._e(),_c('v-pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.totalPages > 0),expression:"totalPages > 0"}],attrs:{"total-visible":10,"length":_vm.totalPages,"disabled":_vm.loading},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }